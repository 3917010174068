import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from 'reactstrap';
import styled from 'styled-components';

const SubHeader = styled.h3`
  margin-top: 20px;
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  letter-spacing: 3px;
`;
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      agree: false,
      isModalOpen: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  toggleModal() {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    console.log('Current state is: ' + JSON.stringify(this.state));
    alert('Current state is: ' + JSON.stringify(this.state));
    event.preventDefault();
  }
  render() {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='row '>
            <div className='col-12'>
              <div outline onClick={this.toggleModal}>
                <b> Contact</b>
              </div>
            </div>
          </div>
        </div>
        <div className=' container modal-skill'>
          <Modal
            className='my-modals'
            isOpen={this.state.isModalOpen}
            toggle={this.toggleModal}
            style={{ maxWidth: '1000px', width: '100%', height: '100%' }}
          >
            <ModalHeader toggle={this.toggleModal}>
              <h2 className='about-t'>CONTACT</h2>
            </ModalHeader>
            <ModalBody>
              <div className='row row-content'>
                <div>
                  <h2 className='col mb-3 '>
                    Dropping a line to say good day, see if we can build
                    something amazing together? I’d love to hear from you!
                  </h2>
                </div>
              </div>
              <div className='row row-about '>
                <div className='col-md-6 col-row-form'>
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                      <Label htmlFor='name' md={1}></Label>
                      <Col lg={12}>
                        <Input
                          className='text-message'
                          type='text'
                          id='name'
                          name='name'
                          placeholder='Name'
                          value={this.state.name}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor='Email' md={1}></Label>
                      <Col lg={12}>
                        <Input
                          className='text-message'
                          type='text'
                          id='email'
                          name='email'
                          placeholder='Email'
                          value={this.state.email}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor='subject' md={1}></Label>
                      <Col lg={12}>
                        <Input
                          className='text-message'
                          type='text'
                          id='subject'
                          name='subject'
                          placeholder='Subject'
                          value={this.state.subject}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label htmlFor='message' md={1}></Label>
                      <Col lg={12}>
                        <Input
                          type='textarea'
                          id='message'
                          name='message'
                          rows='12'
                          placeholder='Message'
                          value={this.state.message}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col md={{ size: 11, offset: 1 }}>
                        <Button className='sendMessage' type='submit'>
                          SEND MESSAGE
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                <div className='col-md-6 text-center'>
                  <SubHeader>Find me on </SubHeader>
                  <a
                    className='btn btn-social-icon btn-linkedin'
                    href='https://www.linkedin.com/in/ttewab/'
                    target='_blank' rel="noreferrer"
                  >
                    <i className='fa fa-linkedin' />
                  </a>{' '}
                  <a
                    className='btn btn-social-icon btn-github'
                    href='https://githu.com/ttewabe'
                    target='_blank' rel="noreferrer"
                  >
                    <i className='fa fa-github' />
                  </a>{' '}
                  <a
                    className='btn btn-social-icon btn-twitter'
                    href='http://twitter.com/'
                    target='_blank' rel="noreferrer"
                  >
                    <i className='fa fa-twitter' />
                  </a>{' '}
                  <a
                    className='btn btn-social-icon btn-google'
                    href='https://ww.youtube.com/channel/UCB7pGlj43FBV5KSQjmxIjIw'
                    target='_blank' rel="noreferrer"
                  >
                    <i className='fa fa-youtube' />
                  </a>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default Contact;
